const FeatureComponent = () => {
  return (
    <section
      data-bs-version="5.1"
      className="features3 cid-tn6lRozdmI"
      id="features3-v"
    >
      <div className="container">
        <div className="mbr-section-head">
          <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
            <strong>Features</strong>
          </h4>
          <h5 className="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">
            To add more cards, hover on a card and click Add items
          </h5>
        </div>
        <div className="row mt-4">
          <div className="item features-image сol-12 col-md-6 col-lg-3">
            <div className="item-wrapper">
              <div className="item-img">
                <img
                  src="assets/images/16-596x500.png"
                  alt="woman flower nft"
                  data-slide-to={0}
                  data-bs-slide-to={0}
                />
              </div>
            </div>
          </div>
          <div className="item features-image сol-12 col-md-6 col-lg-3">
            <div className="item-wrapper">
              <div className="item-img">
                <img
                  src="assets/images/41-1-596x843.png"
                  alt="woman flower nft"
                  data-slide-to={1}
                  data-bs-slide-to={1}
                />
              </div>
            </div>
          </div>
          <div className="item features-image сol-12 col-md-6 col-lg-3">
            <div className="item-wrapper">
              <div className="item-img">
                <img
                  src="assets/images/4-596x500.png"
                  alt="woman flower nft"
                  data-slide-to={2}
                  data-bs-slide-to={2}
                />
              </div>
            </div>
          </div>
          <div className="item features-image сol-12 col-md-6 col-lg-3">
            <div className="item-wrapper">
              <div className="item-img">
                <img
                  src="assets/images/21-1-1587x2245.png"
                  alt="woman flower nft"
                  data-slide-to={3}
                  data-bs-slide-to={3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureComponent;
