const GalleryComponent = () => {
  return (
    <section
      data-bs-version="5.1"
      className="gallery4 mbr-gallery cid-tn9TIcAsm4"
      id="gallery4-10"
    >
      <div className="container">
        <div className="mbr-section-head">
          <h3 className="mbr-section-title mbr-fonts-style align-center m-0 display-2">
            <strong>Some of Our NFTs Collections For Free</strong>
          </h3>
          <h4 className="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">
            You can mint it with Solana Wallet Phantom Wallet
          </h4>
        </div>
        <div className="row mbr-gallery mt-4">
          <div className="col-12 col-md-6 col-lg-6 item gallery-image">
            <div
              className="item-wrapper"
              data-toggle="modal"
              data-bs-toggle="modal"
              data-target="#tna07nwWbd-modal"
              data-bs-target="#tna07nwWbd-modal"
            >
              <img
                className="w-100"
                src="assets/images/8-1-940x788.png"
                alt="woman flower nft"
                data-slide-to={0}
                data-bs-slide-to={0}
                data-target="#lb-tna07nwWbd"
                data-bs-target="#lb-tna07nwWbd"
              />
              <div className="icon-wrapper">
                <span className="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 item gallery-image">
            <div
              className="item-wrapper"
              data-toggle="modal"
              data-bs-toggle="modal"
              data-target="#tna07nwWbd-modal"
              data-bs-target="#tna07nwWbd-modal"
            >
              <img
                className="w-100"
                src="assets/images/0-2-1080x1080.png"
                alt="woman flower nft"
                data-slide-to={1}
                data-bs-slide-to={1}
                data-target="#lb-tna07nwWbd"
                data-bs-target="#lb-tna07nwWbd"
              />
              <div className="icon-wrapper">
                <span className="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 item gallery-image">
            <div
              className="item-wrapper"
              data-toggle="modal"
              data-bs-toggle="modal"
              data-target="#tna07nwWbd-modal"
              data-bs-target="#tna07nwWbd-modal"
            >
              <img
                className="w-100"
                src="assets/images/40-1-1288x1822.png"
                alt="woman flower nft"
                data-slide-to={2}
                data-bs-slide-to={2}
                data-target="#lb-tna07nwWbd"
                data-bs-target="#lb-tna07nwWbd"
              />
              <div className="icon-wrapper">
                <span className="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 item gallery-image">
            <div
              className="item-wrapper"
              data-toggle="modal"
              data-bs-toggle="modal"
              data-target="#tna07nwWbd-modal"
              data-bs-target="#tna07nwWbd-modal"
            >
              <img
                className="w-100"
                src="assets/images/33-1-1288x1822.png"
                alt="woman flower nft"
                data-slide-to={3}
                data-bs-slide-to={3}
                data-target="#lb-tna07nwWbd"
                data-bs-target="#lb-tna07nwWbd"
              />
              <div className="icon-wrapper">
                <span className="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal mbr-slider"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
          id="tna07nwWbd-modal"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="carousel slide"
                  id="lb-tna07nwWbd"
                  data-interval={5000}
                  data-bs-interval={5000}
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="assets/images/8-1-940x788.png"
                        alt="woman flower nft"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="assets/images/0-2-1080x1080.png"
                        alt="woman flower nft"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="assets/images/40-1-1288x1822.png"
                        alt="woman flower nft"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="assets/images/33-1-1288x1822.png"
                        alt="woman flower nft"
                      />
                    </div>
                  </div>
                  <ol className="carousel-indicators">
                    <li
                      data-slide-to={0}
                      data-bs-slide-to={0}
                      className="active"
                      data-target="#lb-tna07nwWbd"
                      data-bs-target="#lb-tna07nwWbd"
                    />
                    <li
                      data-slide-to={1}
                      data-bs-slide-to={1}
                      data-target="#lb-tna07nwWbd"
                      data-bs-target="#lb-tna07nwWbd"
                    />
                    <li
                      data-slide-to={2}
                      data-bs-slide-to={2}
                      data-target="#lb-tna07nwWbd"
                      data-bs-target="#lb-tna07nwWbd"
                    />
                    <li
                      data-slide-to={3}
                      data-bs-slide-to={3}
                      data-target="#lb-tna07nwWbd"
                      data-bs-target="#lb-tna07nwWbd"
                    />
                  </ol>
                  <a
                    role="button"
                    href="/" 
                    className="close"
                    data-dismiss="modal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></a>
                  <a
                    className="carousel-control-prev carousel-control"
                    role="button"
                    data-slide="prev"
                    data-bs-slide="prev"
                    href="#lb-tna07nwWbd"
                  >
                    <span
                      className="mobi-mbri mobi-mbri-arrow-prev"
                      aria-hidden="true"
                    />
                    <span className="sr-only visually-hidden">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next carousel-control"
                    role="button"
                    data-slide="next"
                    data-bs-slide="next"
                    href="#lb-tna07nwWbd"
                  >
                    <span
                      className="mobi-mbri mobi-mbri-arrow-next"
                      aria-hidden="true"
                    />
                    <span className="sr-only visually-hidden">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryComponent;
