const FooterComponent = () => {
  return (
    <div>
      <section
        data-bs-version="5.1"
        className="footer3 cid-s48P1Icc8J"
        // once="footers"
        id="footer3-i"
      >
        <div className="container">
          <div className="media-container-row align-center mbr-white">
            <div className="row row-copirayt">
              <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                © Copyright 2022 Canplay Studio. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="display-7"
        style={{
          padding: 0,
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          display: "flex",
          position: "relative",
          height: "4rem",
        }}
      >
        &nbsp;
      </section>
    </div>
  );
};

export default FooterComponent;
