const HeaderComponent = () => {
  return (
    <section
      data-bs-version="5.1"
      className="header7 cid-tn6lClFGpB mbr-fullscreen"
      id="header7-u"
    >
      <div className="text-right container">
        <div className="row justify-content-end">
          <div className="col-12 col-lg-5"></div>
        </div>
      </div>
    </section>
  );
};
export default HeaderComponent;
